import PropTypes from "prop-types";
import React from "react";
import { Link } from "react-router-dom";
import { multilanguage } from "redux-multilanguage";

const NavMenuS = ({ strings, menuWhiteClass, sidebarMenu }) => {
  return (
    <div
      className={` ${sidebarMenu
        ? "sidebar-menu"
        : `main-menu ${menuWhiteClass ? menuWhiteClass : ""}`
        } `}
    >
      <nav>
        <ul>

          <li>
            <Link to={process.env.PUBLIC_URL + "/"} className="font-12">
              {" "}
              Curtains
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu custom-hovered-menu">
              <li>
                <Link role="button" className="menu-trigger">Panel</Link>
                <div className="sub-menu-wrapper">
                  <ul>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-kids1"}>
                        kids - little star 1
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-kids2"}>
                        kids little star 2
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula1"}>
                        nebula 1
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula2"}>
                        nebula 2
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula3"}>
                        nebula 3
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula4"}>
                        nebula 4
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula5"}>
                        nebula 5
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula6"}>
                        nebula 6
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula7"}>
                        nebula 7
                      </Link>
                    </li>
                    <li className="ps-3">
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-nebula8"}>
                        nebula 8
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
              <li>
                <Link role="button" className="menu-trigger" >All Over</Link>
                <div className="sub-menu-wrapper">
                  <ul>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-annanta1"}>
                        Annanta-1 (premium)
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-annanta2"}>
                        Annanta-2 (premium)
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-annanta3"}>
                        Annanta-3 (premium)
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-evrest"}>
                        evrest
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-global-collection"}>
                        Global Collection
                      </Link>
                    </li>
                    <li>
                      <Link to={process.env.PUBLIC_URL + "/shop-grid-universe"}>
                        Universe
                      </Link>
                    </li>
                  </ul>
                </div>
              </li>
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12">
              {" "}
              Wallpaper
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu custom-hovered-menu">
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-sofa-fabric"}>
                  Customize Wallpaper
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-sofa-design"}>
                  Roll Wallpaper
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12">
              {" "}
              Blinds
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu custom-hovered-menu">
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-sofa-fabric"}>
                  Roller Blinds
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-sofa-design"}>
                  Zebra Blinds
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/CustomCouch"}>
                  Roman Blinds
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12">
              Wall Frames
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12">
              {" "}
              Upholstery
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu custom-hovered-menu">
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-sofa-fabric"}>
                  Sofa Fabric
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-sofa-design"}>
                  Sofa Design
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/CustomCouch"}>
                  Custom Sofa
                </Link>
              </li>
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12">
              Chairs
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12">
              Cushion
            </Link>
          </li>


          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12">
              Bedsheets
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL} className="font-12 set-center">
              <span>
                <span>Hospitality</span>  <span>(Hotel / Motel) {sidebarMenu ? (
                  <span>
                    <i className="fa fa-angle-right"></i>
                  </span>
                ) : (
                  <i className="fa fa-angle-down" />
                )}</span>
              </span>
            </Link>
            <ul className="mega-menu custom-hovered-menu">
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-metal-bed-frame"}>
                  Metal Bed Frame  (Hot Selling Product)
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-top-sheet"}>
                  Top Sheet
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-bed-linens"}>
                  Bed Linens
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-runners"}>
                  Runners
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-bath-linens"}>
                  Bath Linens
                </Link>
              </li>
              <li>
                <Link role="button" className="menu-trigger" to={process.env.PUBLIC_URL + "/shop-grid-bath-accessories"}>
                  Bath Accessories
                </Link>
              </li>
            </ul>
          </li>

          {/* 
          
           <li>
            <Link to={process.env.PUBLIC_URL + "/"}>
              {" "}
              Curtains
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                      Panel
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-filter"}>
                      Candid
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-two-column"}>
                      Gradient
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>

          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              {" "}
              Wallpaper
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                      Explore Collection
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-filter"}>
                      Kool Kids
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-two-column"}>
                      3rd Orbit
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}>
                      Nature
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-full-width"}>
                      Maps
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              {" "}
              Sofa Fabric
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                      Explore Collection
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-filter"}>
                      Kool Kids
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-two-column"}>
                      3rd Orbit
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}>
                      Nature
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-full-width"}>
                      Maps
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/CustomCouch"}>
                      Custome
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              Chairs
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}>
              Cushion
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}>
              Wallframes
            </Link>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
              {" "}
              Blinds
              {sidebarMenu ? (
                <span>
                  <i className="fa fa-angle-right"></i>
                </span>
              ) : (
                <i className="fa fa-angle-down" />
              )}
            </Link>
            <ul className="mega-menu">
              <li>
                <ul>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-standard"}>
                      Zebra Blinds
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-filter"}>
                      Roller Blinds
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-two-column"}>
                      Stitchless Roman Blinds
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}>
                      Nature
                    </Link>
                  </li>
                  <li>
                    <Link to={process.env.PUBLIC_URL + "/shop-grid-full-width"}>
                      Maps
                    </Link>
                  </li>
                </ul>
              </li>
            </ul>
          </li>
          <li>
            <Link to={process.env.PUBLIC_URL + "/shop-grid-no-sidebar"}>
              Bedsheets
            </Link>
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

NavMenuS.propTypes = {
  menuWhiteClass: PropTypes.string,
  sidebarMenu: PropTypes.bool,
  strings: PropTypes.object
};

export default multilanguage(NavMenuS);

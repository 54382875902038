import React from 'react'
import { Collapse } from "react-bootstrap"

const CollapseMenu = (props) => {
    const { open, children } = props
    return (
        <Collapse in={open}>
            {children}
        </Collapse>
    )
}

export default CollapseMenu

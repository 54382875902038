import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import fs from 'fs';
import path from 'path';
import Paginator from 'react-hooks-paginator';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { getSortedProducts } from '../../helpers/product';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import imageList from '../../assets/image/kids-littel-star-2.json';
import DisplayProduct from "../../components/product/DisplayProduct";



const ShopGridPanelKids2 = ({ location, products }) => {
  const [layout, setLayout] = useState('grid three-column');
  const [sortType, setSortType] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [filterSortType, setFilterSortType] = useState('');
  const [filterSortValue, setFilterSortValue] = useState('');
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);


  const pageLimit = 15;
  const { pathname } = location;

  const getLayout = (layout) => {
    setLayout(layout)
  }

  const getSortParams = (sortType, sortValue) => {
    setSortType(sortType);
    setSortValue(sortValue);
  }

  const getFilterSortParams = (sortType, sortValue) => {
    setFilterSortType(sortType);
    setFilterSortValue(sortValue);
  }

  useEffect(() => {
    // let sortedProducts = getSortedProducts(products, sortType, sortValue);
    // const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);
    let sortedProducts;
    const filterSortedProducts = imageList.images
    sortedProducts = filterSortedProducts;
    setSortedProducts(sortedProducts);
    setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
  }, [offset, products, sortType, sortValue, filterSortType, filterSortValue]);

  return (
    <Fragment>
      <MetaTags>
        <title>Wall Shine | Shop Page</title>
        <meta name="description" content="Shop page of Wall Shine react minimalist eCommerce template." />
      </MetaTags>

      <BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>Shop</BreadcrumbsItem>

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />

        <div className="shop-area pt-5 pb-100 mx-auto">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 order-2 order-lg-1 d-none">
                {/* shop sidebar */}
                <ShopSidebar products={imageList.images} getSortParams={getSortParams} sideSpaceClass="mr-30" />
              </div>
              <div className="col-lg-12 order-1 order-lg-2">
                {/* shop topbar default */}
                <ShopTopbar getLayout={getLayout} getFilterSortParams={getFilterSortParams} productCount={imageList.images.length} sortedProductCount={currentData.length} />

                {/* shop page content default */}
                {/* <ShopProducts layout={layout} products={productsDemo} /> */}

                <DisplayProduct products={currentData} url={"/new_assets/Curtains/panal-customize/kids little star 2/"} />

                {/* shop product pagination */}
                <div className="pro-pagination-style text-center mt-30">
                  <Paginator
                    totalRecords={imageList.images.length}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  )
}

ShopGridPanelKids2.propTypes = {
  location: PropTypes.object,
  products: PropTypes.array
}

const mapStateToProps = state => {
  return {
    products: state.productData.products
  }
}

export default connect(mapStateToProps)(ShopGridPanelKids2);
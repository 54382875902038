import React from 'react';
import { Link } from "react-router-dom"

const DisplayProduct = ({ products, url }) => {
    const handleClick = (product) => {
        window.scrollTo(0, 0);
        localStorage.setItem("product-detail", JSON.stringify({
            product: product,
            url: url
        }))

    }
    return (
        <div className="productGrid">
            {
                products.map((product, index) => (
                    <Link to={`/product/${product?.split(".")[0]}`} key={index} className="productGrid__product" onClick={() => handleClick(product)}>
                        <img src={`${url}${product}`} alt={product?.name} />
                    </Link>
                ))
            }
        </div>
    )
}

export default DisplayProduct
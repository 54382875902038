import PropTypes from "prop-types";
import React, { Fragment, useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import fs from 'fs';
import path from 'path';
import Paginator from 'react-hooks-paginator';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';
import { connect } from 'react-redux';
import { getSortedProducts } from '../../helpers/product';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import ShopSidebar from '../../wrappers/product/ShopSidebar';
import ShopTopbar from '../../wrappers/product/ShopTopbar';
import imageList from '../../assets/image/kids-littel-star-1.json';
import DisplayProduct from "../../components/product/DisplayProduct";
import ShopProducts from "../../wrappers/product/ShopProducts";

const productsDemo = [
  {
    "id": "1",
    "sku": "asdf141",
    "name": "Chair",
    "price": 10.6,
    "discount": 10,
    "new": true,
    "rating": 5,
    "saleCount": 190,
    "category": ["furniture"],
    "tag": ["furniture"],
    "stock": 25,
    "image": [
      "/assets/img/product/furnitures/2.jpg",
      "/assets/img/product/furnitures/3.jpg",
      "/assets/img/product/furnitures/4.jpg",
      "/assets/img/product/furnitures/5.jpg",
      "/assets/img/product/furnitures/6.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
  },
  {
    "id": "2",
    "sku": "asdf142",
    "name": "Lorem ipsum chair three",
    "price": 12.5,
    "discount": 20,
    "new": true,
    "rating": 3,
    "saleCount": 90,
    "category": ["furniture"],
    "tag": ["furniture"],
    "stock": 20,
    "image": [
      "/assets/img/product/furnitures/3.jpg",
      "/assets/img/product/furnitures/4.jpg",
      "/assets/img/product/furnitures/5.jpg",
      "/assets/img/product/furnitures/6.jpg",
      "/assets/img/product/furnitures/7.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
  },
  {
    "id": "3",
    "sku": "asdf143",
    "name": "Lorem ipsum sofa one",
    "price": 14.5,
    "discount": 30,
    "new": false,
    "rating": 4,
    "saleCount": 100,
    "category": ["furniture"],
    "tag": ["furniture"],
    "stock": 40,
    "image": [
      "/assets/img/product/furnitures/4.jpg",
      "/assets/img/product/furnitures/5.jpg",
      "/assets/img/product/furnitures/6.jpg",
      "/assets/img/product/furnitures/7.jpg",
      "/assets/img/product/furnitures/8.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
  },
  {
    "id": "4",
    "sku": "asdf144",
    "name": "Lorem ipsum sofa two",
    "price": 22.5,
    "discount": 10,
    "new": true,
    "rating": 5,
    "saleCount": 120,
    "category": ["furniture"],
    "tag": ["furniture"],
    "stock": 45,
    "image": [
      "/assets/img/product/furnitures/5.jpg",
      "/assets/img/product/furnitures/6.jpg",
      "/assets/img/product/furnitures/7.jpg",
      "/assets/img/product/furnitures/8.jpg",
      "/assets/img/product/furnitures/1.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
  },
  {
    "id": "5",
    "sku": "asdf145",
    "name": "Lorem ipsum sofa three",
    "price": 12.5,
    "discount": 0,
    "new": false,
    "rating": 4,
    "saleCount": 20,
    "category": ["furniture"],
    "tag": ["furniture"],
    "stock": 5,
    "image": [
      "/assets/img/product/furnitures/6.jpg",
      "/assets/img/product/furnitures/7.jpg",
      "/assets/img/product/furnitures/8.jpg",
      "/assets/img/product/furnitures/1.jpg",
      "/assets/img/product/furnitures/2.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt."
  },
  {
    "id": "2",
    "sku": "asdf124",
    "name": "Lorem ipsum coat",
    "price": 18.5,
    "discount": 15,
    "new": false,
    "rating": 3,
    "saleCount": 12,
    "category": ["fashion", "women"],
    "tag": ["fashion", "women", "coat", "full sleeve"],
    "variation": [
      {
        "color": "blue",
        "image": "/assets/img/product/fashion/2.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 6
          },
          {
            "name": "xl",
            "stock": 7
          }
        ]
      },
      {
        "color": "brown",
        "image": "/assets/img/product/fashion/4.jpg",
        "size": [
          {
            "name": "x",
            "stock": 4
          },
          {
            "name": "m",
            "stock": 8
          },
          {
            "name": "xl",
            "stock": 3
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      },
      {
        "color": "black",
        "image": "/assets/img/product/fashion/5.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 7
          },
          {
            "name": "xl",
            "stock": 0
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      }
    ],
    "image": [
      "/assets/img/product/fashion/2.jpg",
      "/assets/img/product/fashion/4.jpg",
      "/assets/img/product/fashion/5.jpg",
      "/assets/img/product/fashion/7.jpg",
      "/assets/img/product/fashion/9.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
  },
  {
    "id": "3",
    "sku": "asdf125",
    "name": "Lorem ipsum jacket",
    "price": 17.45,
    "discount": 40,
    "offerEnd": "October 2, 2020 12:11:00",
    "new": true,
    "rating": 5,
    "saleCount": 36,
    "category": ["fashion", "men"],
    "tag": ["fashion", "men", "jacket", "full sleeve"],
    "variation": [
      {
        "color": "white",
        "image": "/assets/img/product/fashion/1.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 2
          },
          {
            "name": "xl",
            "stock": 5
          }
        ]
      },
      {
        "color": "black",
        "image": "/assets/img/product/fashion/8.jpg",
        "size": [
          {
            "name": "x",
            "stock": 4
          },
          {
            "name": "m",
            "stock": 7
          },
          {
            "name": "xl",
            "stock": 9
          },
          {
            "name": "xxl",
            "stock": 1
          }
        ]
      },
      {
        "color": "brown",
        "image": "/assets/img/product/fashion/3.jpg",
        "size": [
          {
            "name": "x",
            "stock": 1
          },
          {
            "name": "m",
            "stock": 2
          },
          {
            "name": "xl",
            "stock": 4
          },
          {
            "name": "xxl",
            "stock": 0
          }
        ]
      }
    ],
    "image": [
      "/assets/img/product/fashion/3.jpg",
      "/assets/img/product/fashion/1.jpg",
      "/assets/img/product/fashion/6.jpg",
      "/assets/img/product/fashion/8.jpg",
      "/assets/img/product/fashion/9.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
  },
  {
    "id": "4",
    "sku": "asdf126",
    "name": "Lorem ipsum fashion coat",
    "price": 15.5,
    "discount": 0,
    "new": true,
    "rating": 4,
    "saleCount": 20,
    "category": ["fashion", "women"],
    "tag": ["fashion", "women", "coat", "full sleeve"],
    "variation": [
      {
        "color": "blue",
        "image": "/assets/img/product/fashion/2.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 6
          },
          {
            "name": "xl",
            "stock": 7
          }
        ]
      },
      {
        "color": "brown",
        "image": "/assets/img/product/fashion/4.jpg",
        "size": [
          {
            "name": "x",
            "stock": 4
          },
          {
            "name": "m",
            "stock": 8
          },
          {
            "name": "xl",
            "stock": 3
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      },
      {
        "color": "black",
        "image": "/assets/img/product/fashion/5.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 7
          },
          {
            "name": "xl",
            "stock": 0
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      }
    ],
    "image": [
      "/assets/img/product/fashion/4.jpg",
      "/assets/img/product/fashion/2.jpg",
      "/assets/img/product/fashion/5.jpg",
      "/assets/img/product/fashion/7.jpg",
      "/assets/img/product/fashion/9.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
  },
  {
    "id": "5",
    "sku": "asdf127",
    "name": "Lorem ipsum female coat",
    "price": 25.5,
    "discount": 10,
    "new": true,
    "rating": 5,
    "saleCount": 25,
    "category": ["fashion", "women"],
    "tag": ["fashion", "women", "coat", "full sleeve"],
    "variation": [
      {
        "color": "blue",
        "image": "/assets/img/product/fashion/2.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 6
          },
          {
            "name": "xl",
            "stock": 7
          }
        ]
      },
      {
        "color": "brown",
        "image": "/assets/img/product/fashion/4.jpg",
        "size": [
          {
            "name": "x",
            "stock": 4
          },
          {
            "name": "m",
            "stock": 8
          },
          {
            "name": "xl",
            "stock": 3
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      },
      {
        "color": "black",
        "image": "/assets/img/product/fashion/5.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 7
          },
          {
            "name": "xl",
            "stock": 0
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      }
    ],
    "image": [
      "/assets/img/product/fashion/5.jpg",
      "/assets/img/product/fashion/2.jpg",
      "/assets/img/product/fashion/4.jpg",
      "/assets/img/product/fashion/7.jpg",
      "/assets/img/product/fashion/9.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
  },
  {
    "id": "6",
    "sku": "asdf128",
    "name": "Lorem ipsum jacket",
    "price": 19.85,
    "discount": 0,
    "offerEnd": "October 17, 2020 12:11:00",
    "new": true,
    "rating": 5,
    "saleCount": 65,
    "category": ["fashion", "men"],
    "tag": ["fashion", "men", "jacket", "full sleeve"],
    "variation": [
      {
        "color": "white",
        "image": "/assets/img/product/fashion/1.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 2
          },
          {
            "name": "xl",
            "stock": 5
          }
        ]
      },
      {
        "color": "black",
        "image": "/assets/img/product/fashion/8.jpg",
        "size": [
          {
            "name": "x",
            "stock": 4
          },
          {
            "name": "m",
            "stock": 7
          },
          {
            "name": "xl",
            "stock": 9
          },
          {
            "name": "xxl",
            "stock": 1
          }
        ]
      },
      {
        "color": "brown",
        "image": "/assets/img/product/fashion/3.jpg",
        "size": [
          {
            "name": "x",
            "stock": 1
          },
          {
            "name": "m",
            "stock": 2
          },
          {
            "name": "xl",
            "stock": 4
          },
          {
            "name": "xxl",
            "stock": 0
          }
        ]
      }
    ],
    "image": [
      "/assets/img/product/fashion/6.jpg",
      "/assets/img/product/fashion/3.jpg",
      "/assets/img/product/fashion/1.jpg",
      "/assets/img/product/fashion/8.jpg",
      "/assets/img/product/fashion/9.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
  },
  {
    "id": "7",
    "sku": "asdf129",
    "name": "Lorem ipsum fashion female coat",
    "price": 55.5,
    "discount": 40,
    "new": true,
    "rating": 5,
    "saleCount": 55,
    "category": ["fashion", "women"],
    "tag": ["fashion", "women", "coat", "full sleeve"],
    "variation": [
      {
        "color": "blue",
        "image": "/assets/img/product/fashion/2.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 6
          },
          {
            "name": "xl",
            "stock": 7
          }
        ]
      },
      {
        "color": "brown",
        "image": "/assets/img/product/fashion/4.jpg",
        "size": [
          {
            "name": "x",
            "stock": 4
          },
          {
            "name": "m",
            "stock": 8
          },
          {
            "name": "xl",
            "stock": 3
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      },
      {
        "color": "black",
        "image": "/assets/img/product/fashion/5.jpg",
        "size": [
          {
            "name": "x",
            "stock": 3
          },
          {
            "name": "m",
            "stock": 7
          },
          {
            "name": "xl",
            "stock": 0
          },
          {
            "name": "xxl",
            "stock": 7
          }
        ]
      }
    ],
    "image": [
      "/assets/img/product/fashion/7.jpg",
      "/assets/img/product/fashion/5.jpg",
      "/assets/img/product/fashion/2.jpg",
      "/assets/img/product/fashion/4.jpg",
      "/assets/img/product/fashion/9.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
  },
  {
    "id": "8",
    "sku": "asdf130",
    "name": "Lorem ipsum fashion jacket",
    "price": 29.85,
    "discount": 10,
    "offerEnd": "October 18, 2020 12:11:00",
    "new": false,
    "rating": 5,
    "saleCount": 83,
    "category": ["fashion", "men"],
    "tag": ["fashion", "men", "jacket", "full sleeve"],
    "stock": 0,
    "image": [
      "/assets/img/product/fashion/8.jpg",
      "/assets/img/product/fashion/6.jpg",
      "/assets/img/product/fashion/3.jpg",
      "/assets/img/product/fashion/1.jpg",
      "/assets/img/product/fashion/9.jpg"
    ],
    "shortDescription": "Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur.",
    "fullDescription": "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo. Nemo enim ipsam voluptatem quia voluptas sit aspernatur aut odit aut fugit, sed quia consequuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quisquam est, qui dolorem ipsum quia dolor sit amet, consectetur, adipisci velit, sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem. Ut enim ad minima veniam, quis nostrum exercitationem ullam corporis suscipit laboriosam, nisi ut aliquid ex ea commodi consequatur? Quis autem vel eum iure reprehenderit qui in ea voluptate velit esse quam nihil molestiae consequatur, vel illum qui dolorem eum fugiat quo voluptas nulla pariatur? Nor again is there anyone who loves or pursues or desires to obtain pain of itself, because it is pain, but because occasionally circumstances occur in which toil and pain can procure him some great pleasure. To take a trivial example, which of us ever undertakes laborious physical exercise, except to obtain some advantage from it? But who has any right to find fault with a man who chooses to enjoy a pleasure that has no annoying consequences, or one who avoids a pain that produces no resultant pleasure?"
  },
];


const ShopGridPanelKids1 = ({ location, products }) => {
  const [layout, setLayout] = useState('grid three-column');
  const [sortType, setSortType] = useState('');
  const [sortValue, setSortValue] = useState('');
  const [filterSortType, setFilterSortType] = useState('');
  const [filterSortValue, setFilterSortValue] = useState('');
  const [offset, setOffset] = useState(0);
  const [currentPage, setCurrentPage] = useState(1);
  const [currentData, setCurrentData] = useState([]);
  const [sortedProducts, setSortedProducts] = useState([]);


  const pageLimit = 15;
  const { pathname } = location;

  const getLayout = (layout) => {
    setLayout(layout)
  }

  const getSortParams = (sortType, sortValue) => {
    setSortType(sortType);
    setSortValue(sortValue);
  }

  const getFilterSortParams = (sortType, sortValue) => {
    setFilterSortType(sortType);
    setFilterSortValue(sortValue);
  }

  useEffect(() => {
    // let sortedProducts = getSortedProducts(imageList.images, sortType, sortValue);
    // const filterSortedProducts = getSortedProducts(sortedProducts, filterSortType, filterSortValue);


    // sortedProducts = filterSortedProducts;
    let sortedProducts;
    const filterSortedProducts = imageList.images

    sortedProducts = filterSortedProducts;
    // setSortedProducts(sortedProducts);
    setCurrentData(sortedProducts.slice(offset, offset + pageLimit));
  }, [offset, products, sortType, sortValue, filterSortType, filterSortValue]);

  return (
    <Fragment>
      <MetaTags>
        <title>Wall Shine | Shop Page</title>
        <meta name="description" content="Shop page of Wall Shine react minimalist eCommerce template." />
      </MetaTags>

      <BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>Home</BreadcrumbsItem>
      <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>Shop</BreadcrumbsItem>

      <LayoutOne headerTop="visible">
        {/* breadcrumb */}
        <Breadcrumb />

        <div className="shop-area pt-5 pb-100 mx-auto">
          <div className="container">
            <div className="row">
              <div className="col-lg-3 order-2 order-lg-1 d-none">
                {/* shop sidebar */}
                <ShopSidebar products={imageList.images} getSortParams={getSortParams} sideSpaceClass="mr-30" />
              </div>
              <div className="col-lg-12 order-1 order-lg-2">
                {/* shop topbar default */}
                <ShopTopbar getLayout={getLayout} getFilterSortParams={getFilterSortParams} productCount={imageList.images.length} sortedProductCount={currentData.length} />

                {/* shop page content default */}
                {/* <ShopProducts layout={layout} products={productsDemo} /> */}

                <DisplayProduct products={currentData} url={"/new_assets/Curtains/panal-customize/kids - littel star 1/"} />

                {/* shop product pagination */}
                <div className="pro-pagination-style text-center mt-30">
                  <Paginator
                    totalRecords={imageList.images.length}
                    pageLimit={pageLimit}
                    pageNeighbours={2}
                    setOffset={setOffset}
                    currentPage={currentPage}
                    setCurrentPage={setCurrentPage}
                    pageContainerClass="mb-0 mt-0"
                    pagePrevText="«"
                    pageNextText="»"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </LayoutOne>
    </Fragment>
  )
}

ShopGridPanelKids1.propTypes = {
  location: PropTypes.object,
  products: PropTypes.array
}

const mapStateToProps = state => {
  return {
    products: state.productData.products
  }
}

export default connect(mapStateToProps)(ShopGridPanelKids1);
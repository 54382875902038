
import React, { Fragment, useState, useEffect } from 'react';
import MetaTags from 'react-meta-tags';
import LayoutOne from '../../layouts/LayoutOne';
import Breadcrumb from '../../wrappers/breadcrumb/Breadcrumb';
import { BreadcrumbsItem } from 'react-breadcrumbs-dynamic';

import couch from "../../../src/assets/image/couch/couch-8_xrckc2.png";
import "../../../src/assets/scss/_customeCouch.scss"


const CustomCouch = ({ location }) => {
  const [couchColor, setCouchColor] = useState('#FCFF4D');
  const [gradientColor1, setGradientColor1] = useState('#24243e');
  const [gradientColor2, setGradientColor2] = useState('#302b63');

  const { pathname } = location;

  function updateCouchColor(event) {
    setCouchColor(event.target.value);
  }

  function updateGradientColor1(event) {
    setGradientColor1(event.target.value);
  }

  function updateGradientColor2(event) {
    setGradientColor2(event.target.value);
  }

  function generateRandom() {
    // Generate random colors
    const randomCouchColor = getRandomColor();
    const randomGradientColor1 = getRandomColor();
    const randomGradientColor2 = getRandomColor();

    // Update state with random colors
    setCouchColor(randomCouchColor);
    setGradientColor1(randomGradientColor1);
    setGradientColor2(randomGradientColor2);
  }

  function getRandomColor() {
    const letters = '0123456789ABCDEF';
    let color = '#';
    for (let i = 0; i < 6; i++) {
      color += letters[Math.floor(Math.random() * 16)];
    }
    return color;
  }

  const couchStyle = {
    fill: couchColor,
  };



  return (
    <>


      <Fragment>
        <MetaTags>
          <title>Wall Shine | Shop Page</title>
          <meta name="description" content="Shop page of Wall Shine react minimalist eCommerce template." />
        </MetaTags>

        <BreadcrumbsItem to={process.env.PUBLIC_URL + '/'}>Home</BreadcrumbsItem>
        <BreadcrumbsItem to={process.env.PUBLIC_URL + pathname}>Shop</BreadcrumbsItem>

        <LayoutOne headerTop="visible">
          {/* breadcrumb */}
          <Breadcrumb />
          <div className='container-fluid' style={{ background: `linear-gradient(to right, ${gradientColor1}, ${gradientColor2}, ${gradientColor1})` }}>
            <div className='couch-body'>

              <div className='row'>
                <div className='col-12'>

                  <div className="couch">
                    <svg
                      id="js-couch"
                      className="couch__overlay"
                      xmlns="http://www.w3.org/2000/svg"
                      xmlnsXlink="http://www.w3.org/1999/xlink"
                      preserveAspectRatio="none"
                      width="1000"
                      height="394"
                    >
                      <defs>
                        <path
                          d="M996.35 77.55q-1.85-1.95-8.65-3.75l-62.4-17.1q-9.3-2.75-12.15-2.5-1.8.15-2.85.45l-.75.3q2.25-16.3 3.75-22.05 1.15-4.4 1.4-10.8.2-6.6-.7-10.85-1.25-5.65-3.1-7.8-2.95-3.35-9.65-2.7-5.95.6-39.3 1.7-38.3 1.25-39.45 1.3-10.25.5-126.75.5-5.05 0-54.2 1.3-45.8 1.25-54.05.95-19.45-.45-30.4-.7-20.2-.55-23.1-1.3-22.3-5.85-26.5 1.25-2.65 4.55-3.85 7.9-.6 1.7-.7 2.5-.65-2.2-2.05-4.55-2.75-4.65-6.45-5.2-3.85-.55-13.65-.4-7.4.1-12 .4-.4.05-18.7.9-16.55.8-19.15 1.1-3.4.4-14.6 1.1-11.3.75-13.05.65h-9.8q-8.65-.05-11.45-.4-2.85-.35-9.25-.6-6.7-.15-8.5-.25-2.7-.1-27.75-.1-25.1 0-29.6.1-92.35 1.15-99 1.65-5.15.4-20 0-15.3-.4-24.4-1.25-6.75-.6-21-1.55-12.95-.9-14.85-1.1-6.45-1.05-11.05-1.5-8.7-.85-12.85.5-5.45 1.75-8.1 4.65-3.2 3.4-2.9 8.6.25 4.65 2.1 11.8 1 3.8 2.55 9.1 1 3.85 2.35 10.1-.1 1-1.5 1-1.75 0-7.7.85-7.1 1-9.8 2.05-2.4.9-23 4.75-21.2 3.9-22.05 4.15-8.2 1.85-15.05 3.35Q7.4 69.1 5.65 70.3 2.5 72.45 2 73.1.6 75 .75 79.2q.15 4.15 1.3 12.75.9 6.85 1.45 10 .5 2.75 8.55 54 6.65 42.15 7.35 46.85 1.15 7.65 4.9 28.55 4.55 25.2 6.35 31.2 2.45 8.15 3.8 11.75 1.85 4.9 3.2 5.75 1.25.8 6.85.65 2.75-.05 5.3-.25l23.85.35q.1 0 1 .95t2 .95q1.9 0 3.4-1.4l23.1-.25 43.65.4q135.05 2.15 137.9 1.9 1.25-.1 72.9.5 72.45.65 76.85.45 8.1-.35 64 .4 143.35.95 146 1.1.55.05 75.3.3 74.7.3 79.8.6 8.65.5 68.25-.35l51.75.5 1.6.4q1.95.35 3.8.05 1.45-.25 3.5-.2 1.9 0 3.35-.3 2.1-.45 8.25-.8 6.25-.3 8.75-.05 1.7.2 8 1 5.75.3 7.4-1.75 1.75-2.2 4.95-10.85 2.8-7.55 4.05-12.4.65-2.5 3.6-17.2 2.75-13.75 3.15-14.8.45-1.25 4.45-22.85 4.05-22.4 4.4-24.4.3-1.45 3.75-25.2 3.35-23.2 4-26.3 1.15-5.5 2.35-18.8 1.4-15.7.8-23.7-.6-8.35-3.35-11.15z"
                          id="a"
                        />
                      </defs>
                      <use xlinkHref="#a" style={couchStyle} />
                    </svg>
                    <img
                      className="couch__img"
                      src={"https://s3-us-west-2.amazonaws.com/s.cdpn.io/1376484/couch-8_xrckc2.png"}
                      alt=""
                    />
                  </div>

                  <div className="colours">
                    <div className="colours__labels">
                      <span>Sofa</span>
                      <span>Gradient</span>
                      <span></span>
                    </div>
                    <div className="colours__inputs">
                      <input id="js-color-1" type="color" className='jscolor' value={couchColor} onChange={updateCouchColor} />
                      <input id="js-color-2" type="color" className='jscolor' value={gradientColor1} onChange={updateGradientColor1} />
                      <input id="js-color-3" type="color" className='jscolor' value={gradientColor2} onChange={updateGradientColor2} />
                      <button className="gen-random" onClick={generateRandom}>
                        Generate random
                      </button>
                    </div>
                  </div>
                </div>

              </div>

            </div >
          </div>
        </LayoutOne>
      </Fragment>

    </>

  );
};

export default CustomCouch;

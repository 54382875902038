import React, { useEffect, useState } from "react";
import MobileMenuSearch from "./sub-components/MobileSearch";
import MobileNavMenu from "./sub-components/MobileNavMenu";
import MobileLangCurChange from "./sub-components/MobileLangCurrChange";
import MobileWidgets from "./sub-components/MobileWidgets";
import { Link } from "react-router-dom"
import CollapseMenu from "../common/CollapseMenu";

const MobileMenu = () => {

  const [curtainsOpen, setCurtainsOpen] = useState(false);
  const [panelOpen, setPanelOpen] = useState(false);
  const [allOverOpen, setAllOverOpen] = useState(false);


  useEffect(() => {
  });

  const sideMenuExpand = e => {
    e.currentTarget.parentElement.classList.toggle("active");
  };

  const closeMobileMenu = () => {
    const offcanvasMobileMenu = document.querySelector(
      "#offcanvas-mobile-menu"
    );
    offcanvasMobileMenu.classList.remove("active");
  };

  return (
    <div className="offcanvas-mobile-menu" id="offcanvas-mobile-menu">
      <button
        className="offcanvas-menu-close"
        id="mobile-menu-close-trigger"
        onClick={() => closeMobileMenu()}
      >
        <i className="pe-7s-close"></i>
      </button>
      <div className="offcanvas-wrapper">
        <div className="offcanvas-inner-content">
          {/* mobile search */}
          {/* <MobileMenuSearch /> */}

          {/* mobile nav menu */}
          {/* <MobileNavMenu /> */}

          <ul className="custom-mobile-SideMenu">
            <li>
              <div onClick={() => setCurtainsOpen(!curtainsOpen)} aria-expanded={curtainsOpen} className="flex justify-content-center align-items-center"> <span>Curtains</span>
                <span className="mobile-menu-side-icon px-2 ">
                  {curtainsOpen ? (
                    <span>
                      <i className="fa fa-angle-right"></i>
                    </span>
                  ) : (
                    <i className="fa fa-angle-down" />
                  )}
                </span>

              </div>
              <CollapseMenu open={curtainsOpen}>
                <div id="curtains" className="mt-1">
                  <ul className="list-unstyled">
                    <li className="m-0 p-0">
                      <div onClick={() => setPanelOpen(!panelOpen)} className="flex justify-content-center align-items-center nav-link">
                        <span className="">Panel</span>
                        <span className="mobile-menu-side-icon px-2 ">
                          {panelOpen ? (
                            <span>
                              <i className="fa fa-angle-right"></i>
                            </span>
                          ) : (
                            <i className="fa fa-angle-down" />
                          )}
                        </span>
                      </div>
                      <CollapseMenu open={panelOpen}>
                        <ul>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-kids1"} >
                              <span className="">kids - little star 1</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-kids2"} >
                              <span className="">kids little star 2</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-nebula1"} >
                              <span className="">nebula 1</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-nebula2"} >
                              <span className="">nebula 2</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-nebula5"} >
                              <span className="">nebula 5
                              </span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-nebula6"} >
                              <span className="">nebula 6</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-nebula7"} >
                              <span className="">nebula 7
                              </span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-nebula8"} >
                              <span className="">nebula 8
                              </span>
                            </Link>
                          </li>
                        </ul>
                      </CollapseMenu>
                    </li>
                    <li className="m-0 p-0">
                      <div className='flex justify-content-center align-items-center nav-link' onClick={() => setAllOverOpen(!allOverOpen)} >
                        <span className="">All Over</span>
                        <span className="mobile-menu-side-icon px-2 ">
                          {panelOpen ? (
                            <span>
                              <i className="fa fa-angle-right"></i>
                            </span>
                          ) : (
                            <i className="fa fa-angle-down" />
                          )}
                        </span>
                      </div>
                      <CollapseMenu open={allOverOpen}>
                        <ul>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-annanta1"} className=''>
                              <span className="">Annanta-1 (premium)
                              </span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-annanta2"} className=''>
                              <span className="">Annanta-2 (premium)</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-annanta3"} className=''>
                              <span className="">Annanta-3 (premium)</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-evrest"} className=''>
                              <span className="">evrest</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-global-collection"} className=''>
                              <span className="">Global Collection</span>
                            </Link>
                          </li>
                          <li className="custom-sub-sub-padding">
                            <Link to={"/shop-grid-universe"} className=''>
                              <span className="">Universe</span>
                            </Link>
                          </li>
                        </ul>
                      </CollapseMenu>
                    </li>
                  </ul>
                </div>
              </CollapseMenu>
            </li>
            <li>
              <div className="">WallPaper</div>
            </li>
            <li>
              <div className="">Blinds</div>
            </li>
            <li>
              <div >Wall Frames</div>
            </li>
            <li>
              <div >Upholstery</div>
            </li>
            <li>
              <div >Chair</div>
            </li>
            <li>
              <div >Cushion</div>
            </li>
            <li>
              <div >Bed Sheet</div>
            </li>
            <li>
              <div >Hospitality (Hotel / Motel)</div>
            </li>
          </ul>

          {/* mobile language and currency */}
          {/* <MobileLangCurChange /> */}

          {/* mobile widgets */}
          <MobileWidgets />
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
